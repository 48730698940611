<template>
  <ciam-page :title-suffix="$t('pageTitles.profile')">
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>{{ $t('settings.user.title') }}</template>
        <template v-slot:subtitle>
          {{ $t('settings.user.subTitle') }}
        </template>
        <template v-slot:actions></template>
      </ciam-card-header>
      <Promised :promise="currentUserPromise">
        <template v-slot="currentUser">
          <div>
            <ciam-card-content>
              <ciam-card-content-lines>
                <ciam-card-content-line>
                  <template v-slot:label>{{ $t('settings.user.email') }}</template>
                  <template v-slot:content>
                    <ciam-text v-if="currentUser">{{ currentUser.email }}</ciam-text>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>{{ $t('settings.user.name') }}</template>
                  <template v-slot:content>
                    <ciam-text v-if="currentUser">{{ currentUser.firstName }} {{ currentUser.lastName }}</ciam-text>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>{{ $t('settings.user.password') }}</template>
                  <template v-slot:content>
                    <div>
                      <ciam-button class="secondary" @click="openUpdatePassword()">
                        {{ $t('settings.user.change') }}
                      </ciam-button>
                    </div>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line v-if="userAttr.subscribeNl">
                  <template v-slot:label>{{ $t('settings.user.newsletter.title') }}</template>
                  <template v-slot:content>
                    <ciam-button @click="toggleSubscriptionToNl(false)" class="secondary">{{
                      $t('settings.user.newsletter.unsubscribe') }}</ciam-button>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line v-else>
                  <template v-slot:label>{{ $t('settings.user.newsletter.title') }}</template>
                  <template v-slot:content>
                    <div>
                      <ciam-button @click="toggleSubscriptionToNl(true)" class="secondary">{{
                        $t('settings.user.newsletter.subscribe') }}</ciam-button>
                    </div>
                  </template>
                </ciam-card-content-line>
              </ciam-card-content-lines>
            </ciam-card-content>
          </div>
        </template>
      </Promised>
    </ciam-card>
    <ciam-card class="mt-4">
      <ciam-card-header>
        <template v-slot:label>{{ $t('settings.user.multipleFactors') }}</template>
        <template v-slot:header>{{ $t('settings.user.authentication.title') }}</template>
        <template v-slot:subtitle>
          {{ $t('settings.user.authentication.subTitle') }}
        </template>
        <template v-slot:actions></template>
      </ciam-card-header>
      <Promised :promise="currentUserPromise">
        <template>
          <div>
            <ciam-card-content>
              <ciam-card-content-lines>
                <ciam-card-content-line>
                  <template v-slot:label>{{ $t('settings.user.multipleFactors') }}</template>
                  <template v-slot:content>
                    <div>
                      <ciam-button class="secondary" @click="activateMFA()">
                        {{ $t('settings.user.authentication.configurate') }}
                      </ciam-button>
                    </div>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line v-if="currentUserDevices.length != 0">
                  <template v-slot:label>{{ $t('settings.user.authentication.devices') }}</template>
                  <template v-slot:content>
                    <div>
                      <b-table style="clear: both" :striped="true" :hoverable="true" :data="currentUserDevices"
                        :row-class="(row) => (row.highlighted ? 'highlight' : '') + 'device--row'">
                        <b-table-column field="name" :label="$t('settings.user.authentication.name')" v-slot="props">
                          <ciam-text>{{ props.row.label }}</ciam-text>
                        </b-table-column>
                        <b-table-column field="date" :label="$t('settings.user.authentication.createdDate')"
                          v-slot="props">
                          <ciam-text>{{ getFormatDate(new Date(props.row.createdDate)) }}</ciam-text>
                        </b-table-column>
                        <b-table-column field="delete" :label="$t('settings.user.authentication.actions')"
                          v-slot="props">
                          <ciam-button class="danger" @click="openPopUp(props.row)">
                            <ciam-icon name="fa-trash"></ciam-icon>
                          </ciam-button>
                        </b-table-column>
                      </b-table>
                    </div>
                  </template>
                </ciam-card-content-line>
              </ciam-card-content-lines>
            </ciam-card-content>
          </div>
        </template>
      </Promised>
    </ciam-card>
    <ciam-card class="mt-4">
      <ciam-card-header>
        <template v-slot:header>{{ $t('settings.user.dangerzone.title') }}</template>
        <template v-slot:subtitle>
          {{ $t('settings.user.dangerzone.subtitle') }}
        </template>
        <template v-slot:actions></template>
      </ciam-card-header>
      <Promised :promise="currentUserPromise">
        <template>
          <div>
            <ciam-card-content>
              <ciam-card-content-lines>
                <ciam-card-content-line>
                  <template v-slot:label>{{ $t('settings.user.dangerzone.delete') }}</template>
                  <template v-slot:content>
                    <div>
                      <ciam-button class="danger" @click="showDeleteUser()">
                        {{ $t('settings.user.dangerzone.delete') }}
                      </ciam-button>
                    </div>
                  </template>
                </ciam-card-content-line>

              </ciam-card-content-lines>
            </ciam-card-content>
          </div>
        </template>
      </Promised>
    </ciam-card>
    <ciam-card>
      <b-modal :active.sync="isOpened" has-modal-card trap-focus :can-cancel="['button']" :destroy-on-hide="true"
        aria-role="dialog" aria-modal :width="600">
        <standard-modal v-if="isOpened" :item="currentDevice" :buttons="buttons" @result="removeDevice()">
          <template v-slot:header>{{ $t('settings.user.authentication.modal.title') }}</template>
          <template v-slot:default="props">
            <p>
              <b>{{ $t('settings.user.authentication.modal.subTitle') }}</b>
            </p>
            <p>{{ $t('settings.user.authentication.modal.text') }}</p>
            <p style="padding-top: 30px">
              {{ ` ${$t('settings.user.authentication.modal.deletionName')} "${props.item.label}" ` }}:
              <ciam-input type="text" v-model="confirmationNameForDeletionDevice" :placeholder="props.item.label" />
            </p>
          </template>
        </standard-modal>
      </b-modal>
    </ciam-card>

    <ciam-card>
      <b-modal :active.sync="isDeleteUserOpened" has-modal-card trap-focus :can-cancel="['button']"
        :destroy-on-hide="true" aria-role="dialog" aria-modal :width="600">
        <standard-modal v-if="isDeleteUserOpened" :buttons="buttons" :item="{ email: $keycloak.userInfo.email }"
          @result="deleteUser()">
          <template v-slot:header>{{ $t('settings.user.dangerzone.title') }}</template>
          <template v-slot:default="props">
            <p>
              <b>{{ $t('settings.user.dangerzone.modal.subtitle') }}</b>
            </p>
            <p>{{ $t('settings.user.dangerzone.modal.text') }}</p>
            <p style="padding-top: 30px">
              {{ ` ${$t('settings.user.dangerzone.modal.deletionName')} "${props.item.email}" ` }}:
              <ciam-input type="text" v-model="confirmationNameForDeletionUser" :placeholder="props.item.email" />
            </p>
          </template>
        </standard-modal>
      </b-modal>
    </ciam-card>
    <ciam-card-content-line type="full-width" v-if="problem">
      <template v-slot:content>
        <ciam-alert :title="problem.title" :description="problem.detail" :type="AlertStatus.ALERT"></ciam-alert>
      </template>
    </ciam-card-content-line>
  </ciam-page>
</template>



<script>
import { makeQueryablePromise } from '@/util/promise';
import userService from '@/pages/UserService';
import { intOr } from '@/util/fp';
import StandardModal from '@/components/StandardModal';
import CiamInput from '@/components/CiamInput';
import { AlertStatus } from '@/components/CiamAlert';
import CiamAlert from '@/components/CiamAlert';
import { Notification } from 'vue-notifyjs';
import UserService from '../UserService';


export default {
  name: 'User',
  components: {
    StandardModal,
    CiamInput,
    CiamAlert
  },
  data() {
    return {
      AlertStatus: AlertStatus,
      problem: null,
      isAbleToDelete: true,
      isSubscribedToNL: true,
      buttons: [
        { text: this.$t('actions.cancel') },
        {
          text: this.$t('actions.delete'),
          classes: ['is-danger', 'is-outlined'],
          returnVal: true,
        },
      ],
      confirmationNameForDeletionDevice: '',
      confirmationNameForDeletionUser: '',
      isOpened: false,
      isDeleteUserOpened: false,
      currentUserPromise: new Promise(() => { }),
      changingPassword: false,
      changePasswordPromise: makeQueryablePromise(Promise.resolve()),
      currentUserDevices: [],
      currentDevice: {},
      userInfo: {},
      userAttr: {}
    };
  },
  methods: {
    showDeleteUser() {
      this.isDeleteUserOpened = true
    },
    async deleteUser() {
      if (this.confirmationNameForDeletionUser !== this.userInfo.email) {
        alert(this.$t('settings.user.dangerzone.modal.mismatch'));
        this.confirmationNameForDeletionUser = '';
        return false;
      }
      try {
        await userService.deleteUser(this.$keycloak.subject).then(() => {
          this.$keycloak.login();
        });
      } catch (err) {
        this.problem = err
      }


    },
    openUpdatePassword() {
      this.currentUserPromise.then(() => {
        this.changePasswordPromise = this.$keycloak.login({ action: 'UPDATE_PASSWORD' });
      });
    },
    async activateMFA() {
      this.currentUserPromise.then(async () => {
        await userService.createDevice(this.$keycloak.subject).then(() => {
          this.$keycloak.login();
        },
          (err) => {
            console.error(err);
            Notification.notify({
              message: 'Could not activate MFA: ' + err.detail,
              type: 'danger',
            });
          });
      });
    },
    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },
    async getAllDeviceName() {
      this.currentUserDevices = [];
      await userService.getMFADevices(this.$keycloak.subject).then((data) =>
        data.forEach((item) => {
          this.currentUserDevices.push({ id: item.id, label: item.label, createdDate: item.createdDate });
        })
      );
    },
    openPopUp(device) {
      this.isOpened = true;
      this.currentDevice = device;
    },
    async removeDevice() {
      if (this.confirmationNameForDeletionDevice !== this.currentDevice.label) {
        alert(this.$t('settings.user.authentication.modal.mismatch'));
        this.confirmationNameForDeletionDevice = '';
        return false;
      }
      try {
        await userService.removeMFADevice(this.$keycloak.subject, this.currentDevice.id);
        alert(this.$t('settings.user.authentication.modal.deleted'));
        this.$router.go();
      } catch (err) {
        alert(
          err.status === 400
            ? this.$t('settings.user.authentication.modal.error.400')
            : this.$t('setting.user.authentification.modal.error.unknown')
        );
      }
    },
    getFormatDate(date) {
      return date.toDateString();
    },
    async getUserAttributes() {
      this.userAttr = await UserService.getUser(this.$keycloak.userInfo.id);
    },
    async toggleSubscriptionToNl(newValue) {
      await userService.toggleSubscriptionToNl(this.userAttr.id, newValue)
      Notification.notify({
        message: this.getNewsletterNotificationText(newValue),
        type: 'info',
      });
      await this.getUserAttributes();
    },
    getNewsletterNotificationText(value) {
      return value ? "You have been registered to the Cloud-IAM newsletter" : "You have been unsubscribed of the Cloud-IAM newsletter";
    }
  },
  async created() {
    this.getAllDeviceName();
    this.getUserAttributes();
    this.userInfo = { ...this.$keycloak.userInfo };
  },
  mounted() {
    this.currentUserPromise = this.$keycloak.loadUserInfo();
  },
};
</script>
